export interface InviewPortType {
    callback: () => void;
    target: HTMLElement | null;
    options: IntersectionObserverInit | undefined;
    freezeOnceVisible: boolean;
}

export const checkInViewIntersectionObserver = ({
    target,
    options = { root: null, rootMargin: '0%', threshold: 0 },
    callback,
    freezeOnceVisible = false,
}: InviewPortType) => {
    const funCallback: IntersectionObserverCallback = (
        entries: IntersectionObserverEntry[],
        observer: IntersectionObserver
    ) => {
        entries.map((entry: IntersectionObserverEntry) => {
            if (entry.isIntersecting) {
                //
                callback();
                //  ---- IF TRUE WE WILL UNOBSERVER AND FALSE IS NO
                if (freezeOnceVisible) {
                    observer.unobserve(entry.target);
                }
            }
            return true;
        });
    };

    // _checkBrowserSupport-----
    if (typeof window.IntersectionObserver === 'undefined') {
        return;
    }

    const observer = new IntersectionObserver(funCallback, options);

    if (target != null) {
        observer.observe(target);
    }
};
interface Coordinates {
    lat: number;
    lng: number;
}
const degreeToRadian = (deg: number): number => deg * (Math.PI / 180);
// export const isLocalhost = () => ['localhost', '127.0.0.1'].includes(window.location.hostname);

export const isLocalhost = () => process.env.NODE_ENV === 'development';

export const getServerUrl = () => {
    if (isLocalhost()) {
        return 'https://localhost:3000';
    }

    return process.env['NEXT_PUBLIC_SERVER_URL'] ?? 'https://clubpicker-server.onrender.com';
};

type FetchGracefullyType = (url: RequestInfo, options?: RequestInit) => Promise<Response | null>;

export const fetchGracefully: FetchGracefullyType = async (url, options = undefined) => {
    try {
        return await fetch(url, options);
    } catch (_error) {
        // TODO: LOG INTO SENTRY
        return null;
    }
};
export const getDistanceInM = (point1: Coordinates, point2: Coordinates): number => {
    const earthRadiusInKm = 6371;
    const dLat = degreeToRadian(point2.lat - point1.lat);
    const dLng = degreeToRadian(point2.lng - point1.lng);

    const a =
        Math.sin(dLat / 2) * Math.sin(dLat / 2) +
        Math.cos(degreeToRadian(point1.lat)) *
            Math.cos(degreeToRadian(point2.lat)) *
            Math.sin(dLng / 2) *
            Math.sin(dLng / 2);

    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
    const distance = earthRadiusInKm * c;

    return Math.ceil(distance * 100) * 10;
};
